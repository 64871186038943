body {
  font-family: "Poppins", Times, serif;
}


/* toggle for dark-light button */
/* Light Mode */
body.light-mode{
  background-color: #ffffff;
  color: #000000;
}

/* Dark mode */
body.dark-mode {
  background-color: rgb(17,0,31);
  color: #ffffff;
}

.nav-link{
  color: rgb(122,124,135);
}

.facebk {
  background-color: #3b5998;
}
.twit {
  background-color: #55acee;
}
.google {
  background-color: #dd4b39;
}

.lkd {
  background-color: #0082ca;
}

.github {
  background-color: #333333;
}
.copyright {
  background-color: rgba(0, 0, 0, 0.05);
  color: gray;
  font-size: 13px;
}
.insta {
  background-color: #ac2bac;
}

.jobLink {
  text-decoration: none;
}

.imgcard {
  /* width: 20%;
   margin: 0 auto; */
  margin-top: 2rem;
}

.sidebar {
  margin-top: 3rem;
}

.containerForm {
  width: 30%;
  margin: 0 auto;
  margin-top: 3rem;
  background-color: #9ad0995c;
  border-radius: 7px;
}

.page-container {
  padding: 2em;
  /* background-color: white; */
  margin: 2em auto;
  max-width: 800px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.contact-form {
  display: flex;
  flex-direction: column;
}

.contact-form label {
  margin-bottom: 1em;
}

.contact-form input,
.contact-form textarea {
  padding: 0.5em;
  margin-top: 0.5em;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-left: 1rem;
}

.contact-form button {
  padding: 1em;
  border: 10px;
  /* background-color: #333; */
  color: rgb(179, 53, 53);
  border-radius: 4px;
  cursor: pointer;
}

.contact-form button:hover {
  background-color: #555;
}

.updates-list {
  list-style: none;
  padding: 0;
}

.updates-list li {
  padding: 1em;
  border-bottom: 1px solid #ccc;
}

/* Stylesheet.css */
/* Stylesheet.css */

.jobLink {
  text-decoration: none;
  color: inherit;
}

.card {
  border: 1px solid #ddd;
  border-radius: 10px;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  overflow: hidden;
  /* background-color:white; */
  margin-bottom: -1rem;
  width: 100%;
}

.card-img-top {
  max-width: 60%;
  height: auto;
  margin: 5% auto;
}

.card-body {
  padding: 0px;
}

.card-title {
  font-size: 20px;
  margin-top: 1rem;
}

.card-text {
  margin-bottom: 10px;
}

.list-group-item {
  padding: 10px 15px;
}

/* Pagination styles */
/* Pagination container */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0;
  margin-top: 5rem;
}

/* Individual page numbers */
.pagination__link {
  margin: 5px 20px; /* Add space between page numbers */
  padding: 10px 15px;
  border-radius: 5px;
  border: 1px solid #007bff;
  color: #007bff;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Active page */
.pagination__link--active {
  background-color: #007bff;
  color: white;
  font-weight: bold;
  width: 2%;
  display: flex;
  justify-content: center;
  border-radius: 20px;
}

/* Disabled buttons */
.pagination__link--disabled {
  color: #ccc;
  cursor: not-allowed;
}

/* Hover effect */
.pagination__link:hover:not(.pagination__link--disabled):not(
    .pagination__link--active
  ) {
  background-color: #007bff;
  color: white;
}


/* Login Page Css */
.loginCard {
  width: 50%;
  margin: 10rem auto;
}
.adl {
  display: flex;

  justify-content: center;
  padding: 20px;
  background: gainsboro;
}

.btnsub {
  width: 100%;
}


.table {
  width: 80%;
  margin: 0 auto;
  margin-top: 3rem;
}

.jobImg {
  border-radius: 10px;
}
.container {
  display: flex;
  flex-direction: column;
}
.ptc {
  text-decoration: none;
  color: gray;
}
.follow {
  color: gray;
}
.privacycontainer {
  display: container;
  flex-direction: column;
}
.listjob{
  text-decoration: none;
  
}

.col-sm-2{
  width: 25%;
  height: 5%;
}
.categories{
  width: 50%;
  margin: 2rem auto;
}

/* Style for the dropdown container */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Style for the button to trigger the dropdown */
.dropbtn {
  background-color: gray;
  color: white;
  padding: 2px;
  font-size: 14px;
  border: none;
  cursor: pointer;
}

/* Container for the dropdown content */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

/* Dropdown item containing batch names and job lists */
.dropdown-item {
  padding: 12px 16px;
  text-align: left;
}

/* Job list inside the dropdown item */
.job-list {
  display: none;
  padding: 10px 0 0;
}

/* Batch name styling */
.batch-name {
  font-weight: bold;
  cursor: pointer;
}

/* Show the dropdown content when hovering over the dropdown container */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Show job list when hovering over batch name */
.dropdown-item:hover .job-list {
  display: block;
}


.footer {
  margin: 0 auto;
  margin-top: 10rem;
  font-size: 14px;
  display: flex;
  justify-content: space-evenly;
}


/* Media Queries for responsiveness */
/* For small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .containerJob .nav {
    font-size: 14px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
    margin: 0 auto;
    width: 100%;
  }

  .pagination {
    width: 107%;
    margin: 2rem auto;
    margin-top: 5rem;
  }
  .jobcontain {
    width: 80%;
    margin: 0 auto;
  }
  .imgcard {
    margin-top: 2rem;
    display: flex;
  }
  .jobLink {
    text-decoration: none;
    color: inherit;
    display: flex;
    justify-content: center;
    margin: 2rem;
  }
  .jobdetail {
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
    margin-top: 1rem;
    font-size: 14px;
  }

  .card-title {
    margin-top: 1rem;
  }
  .dark-mode-toggle{
    margin-bottom: 1rem;
   }
   .pagination li {
    margin: 0 1px; /* Space between numbers, prev and next */
  }

  .col-sm-2 {
    width: 70%;
    height: 5%;
    display: flex;
    margin: 1rem auto;
}
  .jobdetailList{
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
    margin-top: 1rem;
    font-size: 14px;
    
  }
  .lsrole{
   margin-left: 1rem;
  font-size: 14px;
  }
  .pagination__link {
    display: flex;
    width: 70%;
    border: 1px solid #007bff;
    border-radius: 5px;
    color: #007bff;
    cursor: pointer;
    margin: 1px 3px;
    padding: 1px 2px;
    text-decoration: none;
    transition: background-color .3s ease, color .3s ease;
}

  .footer{
    font-size: 13px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .fhead{
    font-size: 18px;
  }
  .textStyle{
    text-align: center;
  }

  .socialIcon{
    display: flex;
    justify-content: center;
  }

  .btnapply{
    display: flex;
    width: 50%;
    margin: 2% auto;
    justify-content: center;
    border-radius: 10px;
  }
  .btnapplyJobDetail{
    display: flex;
    width: 50%;
    margin: 2% auto;
    justify-content: center;
    border-radius: 15px;
  }
}

/* For medium devices (tablets, 600px to 768px) */
@media only screen and (min-width: 600px) and (max-width: 769px) {
  .containerJob {
    font-size: 14px;
    display: grid;
    /* grid-auto-columns: auto; */
    grid-template-columns: repeat(2, 1fr);
    width: 85%;
    margin: 0 auto;
  }
  .jobLink {
    text-decoration: none;
    color: inherit;
    display: flex;
    justify-content: center;
    margin: 2rem;
  }
  .imgcard {
    width: 80%;
    display: flex;
  }
  .jobdetail {
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
    margin-top: 1rem;
    font-size: 14px;
  }
  .dark-mode-toggle{
    margin-bottom: 1rem;
   }
   .pagination li {
    margin: 0 10px; /* Space between numbers, prev and next */
  }
  .lsimg {
    margin-top: 80%;
    margin-left: 10%;
    height: 13%;
  }
  .jobdetailList{
    width: 80%;
    margin: 1rem auto;
    font-size: 10px;
  }
  .lsrole{
   width: 80%;
   margin: 1rem auto;
    font-size: 10px;
  }
  .col-sm-4{
    width: 50%;
  }
  .col-sm-8{
    width: 50%;
  }
  .footer{
    font-size: 12px;
  }
  .fhead{
    font-size: 14px;
  }
  .btnapply{
    display: flex;
    width: 50%;
    margin: 2% auto;
    justify-content: center;
    border-radius: 10px;
  }
  .btnapplyJobDetail{
    display: flex;
    width: 70%;
    justify-content: start;
    border-radius: 15px;
  }
}

/* For large devices (desktops, 768px and up) */
@media only screen and (min-width: 900px) {
  .containerJob {
    font-size: 18px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 80%;
    margin: 0 auto;
    justify-content: center;
  }
  .jobLink {
    text-decoration: none;
    color: inherit;
    display: flex;
    justify-content: center;
    margin: 2rem;
  }

  .imgcard {
    width: 25rem;
    margin: 2rem auto;
    display: flex;
  }
  
  .jobdetail {
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
    font-size: 14px;
    margin-top: 1rem;
  }
  .dark-mode-toggle{
   margin-right: 1rem;
  }
  .pagination li {
    margin: 0 5px; /* Space between numbers, prev and next */
  }
  .lsimg {
    margin-top: 60%;
    margin-left: 10%;
    height: 20%;
  }
  .jobdetailList{
    width: 80%;
    margin: 1rem auto;
    font-size: 14px;
  }
  .lsrole{
   width: 80%;
   margin: 1rem auto;
    font-size: 15px;
  }
  .btnapply{
    display: flex;
    width: 50%;
    margin: 2% auto;
    justify-content: center;
    border-radius: 10px;
  }
  .btnapplyJobDetail{
    display: flex;
    width: 45%;
    justify-content: start;
    border-radius: 15px;
  }
}

