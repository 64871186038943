.addInvBtn{
    /* margin-left: 85%;
     */
     display: flex;
     justify-content: flex-end;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.filters{
    display: flex;
    margin-top: 2rem;
    margin-bottom: 2rem;
}
.f1, .f2, .f3{
    width: 15%;
    margin: 0 2rem;
}

.invcard{
    display: flex;
    height: 8rem;
}

.title{
    display: flex;
    position: relative;
    margin-left: 8%;
    margin-top: 2%;
}

.icon{
    display: flex;
    flex-direction: column;
    position: absolute;
    margin-left: 2%;
    margin-top: 4%;
}

.views{
    display: flex;
    position: absolute;
    margin-left: 85%;
    margin-top: 5%;
}

.user{
    position: absolute;
    margin-top: 6%;
    margin-left: 8%;
}

.link{
    text-decoration: none;
}

.count{
    position: absolute;
    margin-left: 2rem;
    margin-top: -7%;
}

.iconexp{
    position: relative;
}
.userexp{
    position: absolute;
    margin-top: 5.3%;
    margin-left: 5%;
}
.postdate{
    position: absolute;
    margin-left: 80%;
    margin-top: 5%;
}



.clamped {
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Show only 3 lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    
  }
  
  .expanded {
    display: block; /* Show full content when expanded */
  }
  
  .toggle-button {
    color: #007bff;
    cursor: pointer;
    font-weight: bold;
    text-align: center;
    margin-top: 10px;
  }
  
  /* For Small Devices Smartphones */
@media only screen and (max-width: 600px) {
    .addInvBtn {

    }
    .icon{
        max-width: 8%;
    }
    h5{
        font-size: 13px;
    }
    .user{
        font-size: 10px;
        position: absolute;
        margin-top: 5rem;
        margin-left: 2.5rem;
    }
    .title{
        position: relative;
        justify-content: center;
        align-items: center;
        width: 70%;
        margin: 6% auto;
    }
    
    .count{
    
        font-size: 10px;
        position: absolute;
        width: 70%;
        margin-top: 10%;
        margin-left: 85%;
    
    }
    .eye{
        max-width: 60%;
    }
    
.htitle{
    font-size: 12px; }
    
    .userexp{
        position: absolute;
        font-size: 10px;
        margin-top: 5.5rem;
        margin-left: 8%;
    
    }
    
    .postdate{
        position: absolute;
        font-size: 10px;
        margin-top: 5.5rem;
    
    }
    
    .content{
    font-size: 12px;
    }


}

  /* For medium devices (tablets, 600px to 768px) */
@media only screen and (min-width: 600px) and (max-width: 769px) {

        h5{
            font-size: 15px;
        }

        
            .user {
                font-size: 12px;
                position: absolute;
                margin-top: 5rem;
                margin-left: 10%;
                justify-content: flex-start;
                display: flex;

        }
        .title{
            position: relative;
            justify-content: flex-start;
            width: 80%;
            margin: 4% auto;
        }

           
.htitle{
    font-size: 13px; }
    
    .userexp{
        position: absolute;
        font-size: 12px;
        margin-top: 4.5rem;
        margin-left: 8%;
    
    }
    
    .postdate{
        position: absolute;
        font-size: 12px;
        margin-top: 4.5rem;
    
    }
    
    .content{
    font-size: 13px;
    }

        
}

/* for large devices */

@media only screen and (min-width: 900px) {


}
 